<template>
  <div class="recover-password">
    <div class="recover-password-content">
      <div class="recover-password-content__left">
        <button
          class="recover-password-content__back-button"
          type="button"
          @click="changeView('Login')"
        >
          <i
            class="ion ion-arrow-back-circle-outline recover-password-content__icon"
            v-b-popover.hover.top="$t('createAccount.message.goBack')"
          ></i>
        </button>
      </div>
      <div class="recover-password-content__title">
        <span class="recover-password-content__title--main-title">
          {{ $t("recoverPassword.title") }}</span
        ><br />
        <span class="recover-password-content__title--message">{{
          $t("recoverPassword.text")
        }}</span>
      </div>
      <div action="" class="recover-password-form">
        <div class="recover-password-form__email">
          <label class="recover-password-form__email--label" v-html="$t('login.email')"></label>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="input-container">
              <input
                class="input-container__input"
                type="email"
                v-model="email"
                v-model.trim="email"
              />
            </div>
          </div>
        </div>
        <span v-show="emailError" class="recover-password-form__email--error">
          {{ $t("login.messageError") }}
        </span>
        <div class="mt-2">
          <div class="button-container">
            <button
              class="btn button-container__button"
              v-html="$t('changePassword.buttonTitle')"
              v-loading="loading"
              @click="recoverPassword"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { generalMessage } = require("../../utils/ErrorMessages");

export default {
  name: "RecoverPassword",
  components: {
    // LanguageSelector,
  },
  data() {
    return {
      email: null,
      emailError: false,
      loading: false,
    };
  },
  methods: {
    async recoverPassword() {
      this.loading = true;
      if (!this.email || !this.testEmailFormat(this.email)) {
        this.emailError = true;
      } else {
        this.emailError = false;
        const payload = {
          email: this.email.trim(),
        };
        const response = await this.$store.dispatch("user/forgotPassword", payload);
        generalMessage(response.en);
        this.changeView("Login");
      }
      this.loading = false;
    },
    testEmailFormat(email) {
      const emailRegexp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const emailIsCorrect = emailRegexp.test(email);
      return emailIsCorrect;
    },
    async changeView(nameRouter) {
      await this.$router.push({
        name: nameRouter,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.recover-password {
  width: 100%;
  height: 100vh;
  font-family: $font-family-portal;
  @extend %flex-col-center-center;
  background: url("../../assets/images/camion-autonomo-de-aurora.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.recover-password-content {
  @include card-styles;
  width: 520px;
  color: $color-primary-company;
  box-shadow: 2px 2px 4px #6b7489;
  z-index: 1;
  @media (max-width: 790px) {
    width: 90%;
  }
  img {
    height: 150px;
    width: 150px;
  }
  &__left {
    position: absolute;
    margin-left: 1%;
  }
  &__back-button {
    @include go-back-button;
    @media (max-width: 545px) {
      font-size: 50px;
    }
    @media (max-width: 300px) {
      font-size: 50px;
    }
  }
  &__icon {
    font-size: 50px;
  }
  &__title {
    @extend %flex-col-center-center;
    padding-top: 40px;
    &--main-title {
      @include font-main-title;
    }
    &--message {
      text-align: justify;
      width: 60%;
      margin-bottom: 1rem;
    }
  }
}

.recover-password-form {
  // margin-bottom: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;
  &__email {
    width: 60%;
    display: flex;
    flex-direction: column;
    // display: grid;
    // grid-template-columns: 55% 45%;
    align-items: center;
    margin: 0 auto;
    &--label {
      @include label-input-information;
      display: flex;
      justify-content: flex-end;
    }
    &--error {
      color: $color-error;
      margin-top: 5px;
      // font-weight: bold;
      // @extend %flex-end-center;
      font-size: 0.9rem;
    }
    .ion-alert-circle-outline {
      font-size: 14px;
    }
  }
}

.input-container {
  @include input-information;
  width: 60%;
  margin: auto;
  &__input {
    @include input-password;
    width: 100%;
    text-align: center;
  }
}

.button-container {
  width: 60%;
  margin: 0px auto;
  margin-top: 20px;
  &__button {
    @include primary-button(40px);
    @include font-button-text;
    background-color: $color-primary-company;
    margin: auto;
    &:hover {
      background-color: $color-primary-company-hover;
    }
  }
}

.branch-container {
  width: 100%;
  color: $color-white;
  margin-top: 1.5%;
  margin-bottom: 25px;
  font-style: italic;
  font-weight: normal;
  z-index: 1;
}

.brand-container {
  @include brand-quote($color-white);
}

@media (max-width: 550px) {
  .recover-password-form {
    &__email {
      display: flex;
      flex-direction: column;
      &--error {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
</style>
